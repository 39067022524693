
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    grid: {
      type: String,
      default: 'w-full md:w-1/2 xl:w-1/3',
    },

    placeholder: {
      type: String,
      default: 'NewsCardPlaceholder',
    },
  },
}


import { getProducts } from '~/graphql/fragments-collection.js'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      products: [],
      selectedProducts: [],
      loaded: false,
    }
  },

  async fetch() {
    await this.loadProducts(this.$route?.query?.page)

    this.loaded = true
  },

  methods: {
    addToCart(product) {
      const foundIndex = this.selectedProducts.findIndex(
        (p) => p.id + p.locale === product.id + product.locale
      )

      if (foundIndex >= 0) {
        this.selectedProducts[foundIndex].amount++
        return
      }

      this.scrollDown()

      this.selectedProducts.push(product)
    },

    removeFromCart(index) {
      this.selectedProducts.splice(index, 1)
    },

    async loadProducts() {
      try {
        const res = await this.$graphql.cms.request(getProducts, {
          lang: this.$i18n.locale,
        })

        this.products = res?.entries?.data
      } catch (e) {
        console.log('ProductOverview/loadProducts', e)
      }
    },

    scrollDown() {
      // only scroll down when very first item has been added to cart
      if (this.selectedProducts.length > 0) {
        return
      }

      this.$gsap.to(window, {
        duration: 0.8,
        scrollTo: { y: '.checkout-section', offsetY: 100 },
      })
    },
  },
}


export default {
  props: {
    card: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedLocale: this.card.downloads[0],
      isLoading: false,
    }
  },

  computed: {
    hasOnlyLinks() {
      return this.card.downloads.some(({ link }) => !!link)
    },

    hasOnlyFiles() {
      return this.card.downloads.some(({ file }) => !!file)
    },
  },

  methods: {
    add() {
      this.$gsap
        .timeline({
          defaults: {
            duration: 0.3,
            ease: 'back',
          },
        })
        .to(this.$el.querySelector('svg'), {
          scale: 1.2,
          color: 'green',
        })
        .to(this.$el.querySelector('svg'), {
          scale: 1,
          color: 'inherit',
        })
      this.$emit('add-to-cart', this.mappedProduct())
    },

    mappedProduct() {
      return {
        ...this.card,
        locale: this.selectedLocale.language.value,
        amount: 1,
        comment: this.$removeHtmlTags(this.card.text),
      }
    },

    downloadFile(file) {
      this.isLoading = true

      const selectedFile = file
        ? file.permalink
        : this.selectedLocale.file.permalink
      const name =
        selectedFile.match(/[^/]*$/g)[0] || this.card?.title || 'Document'

      this.$axios
        .get(selectedFile, {
          responseType: 'blob',
        })
        .then((response) => {
          this.isLoading = false

          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = name
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((e) => {
          console.log(e)
          this.isLoading = false
        })
    },
  },
}

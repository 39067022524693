import { render, staticRenderFns } from "./ProductOverview.vue?vue&type=template&id=91fc9346"
import script from "./ProductOverview.vue?vue&type=script&lang=js"
export * from "./ProductOverview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/vercel/path0/components/cards/ProductCard.vue').default,SkeletonLoader: require('/vercel/path0/components/general/SkeletonLoader.vue').default,TrashIcon: require('/vercel/path0/components/icons/TrashIcon.vue').default,TransitionExpand: require('/vercel/path0/components/general/TransitionExpand.vue').default,ProductOrderForm: require('/vercel/path0/components/general/ProductOrderForm.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
